import React from "react"

export default function IndustryLinkLink({ text = "", to = "" }) {
  return (
    <a href={to} rel="noopener noreferrer" target="_blank">
      <span className="industry-link-container fs2">
        {text}
        <svg
          className="industry-link-link-logo"
          width="20px"
          height="22px"
          viewBox="0 0 20 22"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter
              x="-1.0%"
              y="-7.8%"
              width="101.8%"
              height="115.6%"
              filterUnits="objectBoundingBox"
              id="filter-1"
            >
              <feOffset
                dx="0"
                dy="1"
                in="SourceAlpha"
                result="shadowOffsetOuter1"
              ></feOffset>
              <feGaussianBlur
                stdDeviation="0.5"
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
              ></feGaussianBlur>
              <feColorMatrix
                values="0 0 0 0 0.290221632   0 0 0 0 0.290175289   0 0 0 0 0.290237457  0 0 0 0.25 0"
                type="matrix"
                in="shadowBlurOuter1"
                result="shadowMatrixOuter1"
              ></feColorMatrix>
              <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
              </feMerge>
            </filter>
          </defs>
          <g
            id="101419"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="PP-IndustryLinks"
              transform="translate(-1185.000000, -454.000000)"
              fill="#ABC3CF"
              fillRule="nonzero"
            >
              <g id="links" transform="translate(200.000000, 431.000000)">
                <g
                  id="link-8"
                  filter="url(#filter-1)"
                  transform="translate(540.000000, 1.000000)"
                >
                  <path
                    d="M451.924071,25.2776465 L451.924071,27.3801647 L448.052434,27.3801647 L448.052434,39.8974819 L461.859717,39.8974819 L461.859717,31.7644854 L463.912151,31.7644854 L463.912151,42 L446,42 L446,25.2776465 L451.924071,25.2776465 Z M463.540187,22 L463.812135,27.8729162 L461.523852,25.8837422 L454.626972,33.817695 L453.077981,32.4711772 L459.974861,24.5372243 L457.686578,22.5480503 L463.540187,22 Z"
                    id="external-link-icon"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
    </a>
  )
}
