import React from "react"
import Layout from "../components/layout"
import MainImage from "../components/mainImage"
import IndustryLinkLink from "../components/industryLinkLink"
import miBG from "../../static/industrial-links/mi-bg.jpg"
import { Helmet } from "react-helmet"

export default function contact(props) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>pecan pipeline - Industry Links</title>
      </Helmet>
      <MainImage header="Industry Links" src={miBG} />
      <section className="page-content-container industry-links-page">
        <div className="column">
          <IndustryLinkLink
            text="Texas One Call"
            to="https://www.texas811.org/"
          />
          <IndustryLinkLink
            text="North Dakota One Call"
            to="http://www.ndonecall.com/"
          />
          <IndustryLinkLink
            text="Wyoming One Call"
            to="https://www.onecallofwyoming.com/"
          />
          <IndustryLinkLink
            text="North Dakota Industrial Commission"
            to="https://www.nd.gov/ndic/"
          />
          <IndustryLinkLink
            text="Pipeline & Hazardous Material Safety Admin."
            to="https://www.phmsa.dot.gov/"
          />
          <IndustryLinkLink
            text="Common Ground Alliance"
            to="https://commongroundalliance.com/"
          />
          <IndustryLinkLink
            text="American Petroleum Institute"
            to="https://www.api.org/"
          />
        </div>
        <div className="column">
          <IndustryLinkLink
            text="Texas Railroad Commission"
            to="https://www.rrc.state.tx.us/"
          />
          <IndustryLinkLink
            text="Pipeline Association for Public Awareness"
            to="https://pipelineawareness.org/"
          />
          <IndustryLinkLink
            text="National Pipeline Mapping System"
            to="https://www.npms.phmsa.dot.gov/"
          />
          <IndustryLinkLink
            text="National Excavator Initiative (NEI)"
            to="https://www.safeexcavator.com/"
          />
          <IndustryLinkLink text="BUXUS" to="https://www.buxus.io" />
          <IndustryLinkLink
            text="WY Public Service Commission"
            to="https://psc.wyo.gov"
          />
        </div>
      </section>
    </Layout>
  )
}
